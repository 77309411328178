.leadImage img {
  width: 100%;
  object-fit: cover;
  height: 370px;
}

// drop homepage link in nav
ul.plone-nav li.index_html {
  display: none;
}

#portal-anontools {
  position: absolute;
  right: 10px;
  top: 0;
}

.job {
  background-color: #ddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 40px 20px;
  min-height: 220px;

  &__header {
    // flex-direction: row;
    h3 {
      margin: 0;
      font-size: 27px;
      font-weight: 400;
    }
  }

  &__footer {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    a {
      text-decoration: none !important;
      text-transform: uppercase;
    }
  }

  &__logo {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &--abeg {
    .job__logo {
      height: 60px;
      width: 115px;
      background-image: url("/++theme++abeg/images/abegStelle.png");
      background-repeat: no-repeat;
      background-position: right;
    }
  }

  &--bfz {
    .job__logo {
      height: 61px;
      width: 40px;
      background-image: url("/++theme++abeg/images/bfzStelle.png");
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

.select_helper {
  font-size: 16px;
  margin-top: 50px;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 50px;

  &::before {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Down%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='%23666666' stroke-miterlimit='10' stroke-width='4' d='M20 26l11.994 14L44 26' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 7px;
  }
}

select#anbieter {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #666;
  border-radius: 0;
  box-shadow: none;
  color: #666;
  width: 333px;
  padding: 12px;
  height: auto;
}

.template-jobs-view {
  #content header {
    display: none;
  }
  .job-view-wrapper {
    @media screen and (min-width: 992px) {
      display: flex;
    }
  }
  .left {
    flex: 1 1 30%;
    padding: 40px;
  }
  .right {
    padding: 40px;
  }
  @media screen and (min-width: 992px) {
    .right {
      flex: 1 1 70%;
    }
  }

  @media screen and (min-width: 1500px) {
    .left {
      flex: 1 1 calc(100% - 960px);
    }
    .right {
      flex: 1 1 960px;
    }
  }
}

.template-job-view {
  #content header {
    display: none;
  }
}

.job__download {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EDownload%3C/title%3E%3Cdesc%3EA solid styled icon from Orion Icon Library.%3C/desc%3E%3Cpath d='M49.189 31.052l-4.378-4.104L35 37.414V2h-6v35.066l-9.751-11.05-4.498 3.969 17.182 19.473 17.256-18.406z' fill='%23202020' data-name='layer2'%3E%3C/path%3E%3Cpath d='M52 45v11H12V45H6v17h52V45h-6z' fill='%23202020' data-name='layer1'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  display: inline-block;
  padding-left: 26px;
  background-size: 20px;
  background-position: top 5px left 0;
}

.newsItem {
  margin-bottom: 4rem;

  &__title {
    font-weight: bold;
  }

  &__link {
    margin-top: 1rem;
  }

  &.state-private {
    .newsItem__date {
      display: none;
    }
  }
}

#formEpilogue iframe {
  border: none;
  margin-top: 70px;
  margin-bottom: 70px;
}

// newsitem
.portaltype-news-item {
  .documentFirstHeading {
    margin-top: 50px;
    border-bottom: none;
  }

  .newsImageContainer {
    float: none;
    margin-bottom: 60px;
    box-shadow: none;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
    @media screen and (min-width: 992px) {
      float: right;
      margin: 0 0 16px 36px;
    }
  }
}

.plone-modal .plone-modal-dialog .plone-modal-body .modal-image img {
  max-width: 100%;
}

.template-newsitem_view.portaltype-news-item {
  .newdate {
    margin-top: 50px;
    font-weight: 600;
  }
  .documentFirstHeading {
    margin-top: 0;
  }
}

.plone-toolbar-top #edit-zone nav > ul li.active ul {
  top: auto;
  height: inherit;
  max-height: 650px;
  overflow: scroll;
}

// sidebar
@media (min-width: 768px) {
  .seitenleiste {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 99999999;
    margin-top: -80px;

    .socialButtons {
      text-align: center;
      background-color: #00847e;
      padding: 10px;
      width: 50px;
      display: flex;
      flex-direction: column;
      z-index: 10;
      position: absolute;
      right: 0;

      a {
        margin-bottom: 15px;
        position: relative;
        display: block;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .seitenleiste
    .seitenleiste
    .socialButtons
    .seitenleiste
    .socialButtons
    a
    .seitenleiste {
    display: inline-block;
  }

  .seitenleiste;
}
