
.template-ansprechpartner-view {
  h1.documentFirstHeading {
    display: none;
  }

  .flip-card {
    background-color: transparent;
    width: 430px;
    height: 250px;
    perspective: 1000px;
  }

  .flip-card-image {
    object-fit: cover;
    width: 100%;
    height: 100% !important;
  }
  .flip-card-inner {
    position: relative;
    width: 430px;
    height: 250px;
    text-align: left;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-color: #bbb;
    color: black;
  }

  .flip-card-back {
    background-color: #017a75;
    padding: 20px;
    color: white;
    transform: rotateY(180deg);

    a {
      // text-decoration: none !important;
      color: #fff !important;
    }
  }

  .flip-card-title {
    position: absolute;
    bottom: 0px;
    color: white;
    width: 100%;
    backdrop-filter: blur(10px);
    padding-block: 5px;
    font-size: 30px;
    line-height: 36px;
    margin: 0;
    margin-bottom: 14px;
    text-align: center;
  }

  .flip-card-subtitle {
    margin-bottom: 14px;
    font-weight: bold;
  }

  .flip-card-contact {
    padding-top: 16px; 
    
    p {
      margin: 0 0 7px;
    }
  }
  .card-grid {
    display: grid; 
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
    gap: 2rem; 
    margin-top: 20px;
    justify-items: center;
  }

  .page {
    padding-top: 2rem;
    padding-bottom: 2rem; 
    padding-left: 3rem;
    padding-right: 3rem; 
    width: 100%; 
    max-width: 64rem; 
    min-height: 100vh; 
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
  }
  .info-color {
    color: #017a75;
  }

  .info-title {
    font-size: 1.5rem;
    line-height: 2rem; 
    font-weight: 600; 
    text-transform: uppercase; 
  }

  .info-description {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem; 
    // line-height: 1.25rem; 
  }

  .info-subtitle {
    font-size: 1.5rem;
    line-height: 2rem; 
    font-weight: 600; 
    text-transform: uppercase;
  }

  .info-tapinfo {
    color: #5c6067;
    font-size: 1.2rem;
    line-height: 1.25rem; 
    font-style: italic; 
    text-align: right; 
    display: none;
  }

  @media (max-width: 950px) { 
    .flip-card {
        width: 400px;
        height: 250px;
    }

    .flip-card-inner {
        width: 400px;
        height: 250px;
    }
  }

  @media (max-width: 890px) { 
    .flip-card {
        width: 350px;
        height: 250px;
    }

    .flip-card-inner {
        width: 350px;
        height: 250px;
    }
  }

  @media (max-width: 790px) { 
    .flip-card {
        width: 300px;
        height: 250px;
    }

    .flip-card-inner {
        width: 300px;
        height: 250px;
    }

    .info-tapinfo {
        display: block;
    }
  }

  @media (max-width: 700px) { 
    .card-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr)); 
        gap: 3rem; 
        justify-items: center;
    }

    .flip-card {
        width: 100%;
        height: 350px;
    }

    .flip-card-inner {
        width: 100%;
        height: 350px;
    }

    .info-title {
        font-size: 1.25rem;
    }
  }

  @media (max-width: 550px) { 
    .info-title {
        font-size: 1rem;
    }

    .flip-card {
        width: 100%;
        height: 300px;
    }

    .flip-card-inner {
        width: 100%;
        height: 300px;
    }
  }
}