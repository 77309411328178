.template-search,
.search-module {
    #content-core {
        ol {
            list-style-type: none;
        }
        a{
            margin-left: auto;
            &:before {
                content: "";
            }
        }
        #search-results {
            .searchResults{
                padding-left: 5px;
            }
            .pagination {
                li {
                    margin-left: 10px;
                    span {
                        display: block;
                    }
                    .arrow::after {
                        padding-left: 5px;
                    }
                }
                ul {
                    padding-left: 5px;
                }
            }
        }
    }
    #searchform {
        .input-group{
            max-width: none;
            display: block;
        }
        .searchPage.form-control {
            border-color: lightgrey;
            color: lightgrey;
            margin-bottom: 20px;
            border-radius: 3px;
            border-width: 3px;
            min-height: 50px;
        }
        .input-group-btn{
            position: absolute;
            z-index: 9;
            right: 40px;
            button{
                background-color: #fff;
                background-image: url('../images/lupe.svg');
                background-size: cover;
                background-position: center;
                width: 40px;
                height: 30px;
                border: none;
                top: 10px;
            }
        }
    }
}
