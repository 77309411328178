//  Leadimage on Modules Page for Block
.template-modules_view .leadImage,
.template-news-overview .leadImage {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;

  .textwrapper {
    flex-basis: calc(50% - 15px);
    padding: 15px;
  }

  .imagewrapper {
    width: 100%;
    img {
      height: 100%;
    }
  }
}

.blocks {
  color: #fff;
  font-weight: 500;
  clear: both;
  
  &__header {
    margin-top: 0px;
    margin-bottom: 40px;
  }
  
  &__link {
    font-weight: 500;
    /*text-transform: uppercase;*/
    font-size: 1.6rem;
    text-decoration: none !important;
    /*
    &::before {
      background-image: url("/++plone++werkbank.abeg/images/arrow.svg");
      content: "";
      display: inline-block;
      height: 25px;
      width: 25px;
      position: relative;
      top: 6px;
    }
    */
  }

  p {
    // @media screen and (min-width:@plone-screen-md-min) {
      font-size: 1.6rem;
    // }
  }

  &__three {
    .row {
      display: flex;
      flex-wrap: wrap;
      flex: 1 0 100%;
      @media screen and (min-width: 992px) {
        flex: 1 1 100%;
        flex-wrap: nowrap;
      }
    }
  }

  &__textimage {
    .row {
      display: flex;
      flex-wrap: wrap;
      min-height: 300px;
      @media screen and (min-width: 1200px) {
        min-height: 480px;
      }
    }

    .block {
      flex: 1 0 100%;
      @media screen and (min-width: 992px) {
        flex: 1 0 50%
      }
    }

    .imagewrapper {
      padding: 0;
      img {
        height: 100% !important;
        width: 100%;
        object-fit: cover;
      }
    }

    &--imageright {
      flex-direction: row-reverse;
    }
  }
}

// Colors
.module {
  // padding: 50px 30px;
  width: 100%;

  &--orange {
    color: #000;
    background-color: @orange;
  }
  
  &--lightgreen {
    background-color: @lightgreen;
    color: #000;
  }
  
  &--pink {
    background-color: @pink;
    color: #fff;

  }
  
  &--darkgreen {
    background-color: @darkgreen;
    color: #fff;
  }
  
  &--violet {
    background-color: @purple;
    color: #fff;
  }
  
  &--blue {
    background-color: @blue;
    color: #fff;
  }

  &--yellow {
    color: #000;
    background-color: @yellow;
  }

  &--white {
    color: #000;
  }

  &--pink,
  &--darkgreen,
  &--violet,
  &--blue {
    a {
      color: #fff;
    }
  }

  &--blue,
  &--violet,
  &--darkgreen {
    .blocks__link {
      color: #fff;
      &::before {
        background-image: url("/++plone++werkbank.abeg/images/arrowwhite.svg");
      }
    }
  }
}

.module, #content-core .module {

  /*h1, h2 {
    font-weight: normal;
    margin-top: 10px;
  }*/

  .module__title {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 50px;
  
  }

  &__text {

    margin-top: 50px;
    margin-left: 0;
    max-width: 100%;
    line-height: 27px;

    @media screen and (min-width: 992px){ 
    margin-left: 33%;
    max-width: 66%;
    }


    h2 {
      font-weight: 700;
      font-size: 20px;

      @media screen and (min-width: 992px) {
        font-size: 27px;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 30px;

      @media screen and (min-width: 992px) {
        font-size: 20px;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 30px;

        @media screen and (max-width: 992px) {
          font-size: 16px;
        }
      }

      li::before {
        content: "✓";
        display: inline-block;
        margin-right: 10px;


        body.violet & {
          color: @purple;
        }

        body.orange & {
          color: @orange;
        }

        body.lightgreen & {
          color: @lightgreen;
        }

        body.pink & {
          color: @pink;
        }

        body.darkgreen & {
          color: @darkgreen;
        }

        body.blue & {
          color: @blue;
        }

        body.yellow & {
          color: @yellow;
        }

    
      }
    }

  }

  &__download {

    background-color: #fff;

    @media screen and (min-width: 920px) {
      //margin: 20px auto 50px calc(33% + 15px);
      max-width: 66%;
      margin: 50px auto 50px 33%;
    }

      .module__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        color: #000;

        @media screen and (min-width: 920px) {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
        }

        .edit-module {
          a {
            color: #000;
          }
          position: absolute;
          bottom: 0;
          margin: 50px 0 -50px 0;
        }

        .module__text {
          margin: 0;
          width: 100%;
        }

      }

      .card__wrapper {
        width: 330px;
        max-width: 100%;
        height: 298px;
        margin: 20px 0 0 0;
        font-size: 20px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: 920px) {
          margin: 20px 20px 0 0;
        }

        .card__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 180px;

          .flexwrapper {
            margin-top: 50px;
            display: inherit;

            img {
              width: 65px;
              height: auto;
            }

            p {
              word-break: break-all;
            }
          }
        }
      }

      &.module{
        &--orange {
          .card__wrapper {
            color: #000;
            background-color: @orange;
          }
        }
        
        &--lightgreen {
          .card__wrapper{
            background-color: @lightgreen;
            color: #000;
          }
        }
        
        &--pink {
          .card__wrapper {
            background-color: @pink;
            color: #fff;
          }
      
        }
        
        &--darkgreen {
          .card__wrapper {
            background-color: @darkgreen;
            color: #fff;
          }
        }
        
        &--violet {
          .card__wrapper {
            background-color: @purple;
            color: #fff;
          }
        }
        
        &--blue {
          .card__wrapper{
            background-color: @blue;
            color: #fff;
          }
        }
      
        &--yellow {
          .card__wrapper{
            color: #000;
            background-color: @yellow;
          }
        }
      
        &--white {
          .card__wrapper {
            color: #000;
          }
        }
      }

      .button {
        display: inline-block;
        padding: 16px 72px; 
        text-decoration: none;
        border-radius: 10px;
        background-color: #fff;
        color: #000;
        font-size: 20px;
        font-weight: 600;
        margin: 40px auto 0;

        &::before {
          display: none;
        }
      }
  }

  &__header {

    picture, img {
      object-fit: cover;
      width: 100%;

      img {
        height: 100%;
      }
    }

    @media screen and (min-width: 992px){ 
      display: flex;
      picture {
        width: 66.6%;
      }
      

      &.module--imgright {
        flex-direction: row-reverse;
      }
    }

    @media screen and (min-width: 1200px){  
      picture {
        width: 66.6%;
      }
    }
    /*
    @media screen and (min-width: 1500px){  
      img {
        object-fit: cover;
        width: 100%;
        max-width: 920px !important
      }
      
    }*/

    .module__content {
      padding: 40px;
      flex: 1 1 auto;
      flex-basis: 50%;
      position: relative;
      min-height: 480px;
      max-width: 460px;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 20px;
        font-weight: 600;
      }

      p {
        position: relative;
        margin: 0 0 14px 0;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        @media screen and (min-width: 920px){
          margin-right: 40px;
        }
      }

      ul {
        width: 100%;
        list-style-type: '✓ ';
      }


    }

  }

  &__imagetext {

    
    .module__content {
      display: flex;
      flex-direction: column-reverse;

      @media screen and (min-width: 992px){
        flex-direction: row-reverse;
      }

      &.imageright {
        @media screen and (min-width: 992px){
          flex-direction: row;
        }
      }

      .textwrapper {
        flex-basis: 50%;
        padding: 40px;
        position:relative;
        min-height: 480px;
        @media screen and (min-width: 992px){
          
          
        }

        ul {
          width: 100%;
          list-style-type: '✓ ';
        }
      }
    
      .imagewrapper {
        width: 100%;
        display: flex;
        background-size: cover;
        min-height: 480px;
        background-position: center;
        @media screen and (min-width: 992px){
          width: 50%;
        }
      }
    }
  }

  &__dreiklang {
    .card__wrapper {
      display: flex;

      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
      }
    }

    .card__item {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      padding: 40px;
      min-width: 33%;

      .card__text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      
      h2 {
        font-weight: 500;
        margin-top: 13px;
        margin-bottom: 56px;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-wrap: normal;
      }

      p {
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      } 

      a {
        text-decoration: none;
        /*text-transform: uppercase;*/
        margin-top: auto;

        &:hover {
          text-decoration: underline;
        }

        
      }
      ul {
        width: 100%;
        list-style-type: '✓';
        color: currentColor;
      }
    
    }

    .card__item.module--violet,
    .card__item.module--darkgreen,
    .card__item.module--pink,
    .card__item.module--blue {
      a {
        color: #fff;
      }
    }
  }  
}