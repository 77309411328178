//*// Site Setup
.portlet.portletSiteSetup .portletContent > ul > li a::before {content:"";}
.portlet.portletSiteSetup .portletContent > ul li:hover {background: #edecec;}
.portletNavigationTree {
    // nav.portletContent > ul > li a {border:none; padding:0px;}
    &.portletSiteSetup {
        nav.portletContent > ul > li a {border:none; padding:0px;color: #4D4D4D;
            &:hover {
                text-decoration: none;
            }   
        }
       
        a {
            // padding-left: 30px; //adjust icons of products
            border: none;
            text-align: center;
            height: 100%;
            padding: 0;
            // img {
            //  position: absolute;
            //  margin-left: -22px;
            //  margin-top: 2px;
            //  display:none; //remove to show images
            // }
            span {font-size: 50px; text-align: center; display: block; color: #4d4d4d;}
        }
        ul {background: #edecec; padding-bottom: 20px;}
        li {vertical-align: top;}
        ul > li > a:hover::after {content:""}
        .inner-configlet {
            margin-top: 10px; padding-bottom: 10px;
            &:hover {
                background: rgba(255,255,255,.67);
                border-radius: 5px;
                box-shadow: inset 1px 1px 3px #bbbbbb;
                span {
                    color: #333;
                }
            }
        }
    }
}
.template-overview-controlpanel .portlet.portletSiteSetup .portletContent > ul > li {height: 120px;}

#portlet-prefs ul ul {
    padding-top: 10px;
    li {
        padding-top: 10px;
        a {
            text-align: inherit;
            padding-left: 40px;
            &::before {
                content:"•"; position: absolute; font-size: @plone-font-size-h3+1; margin-top: -9px; left: 15px;
                color: lighten(@plone-portlet-list-bullet, 15%);
            }
        }
    }
}


.template-usergroup-groupprefs,
.template-usergroup-userprefs,
.template-usergroup-controlpanel,
.template-member-registration {
    .field { margin-top: 2em;}
    .formControls { border-top: 0; box-shadow: none; margin-bottom: 1em; }
    table.listing { margin-bottom: 0.5em; }
    input.quickSearch { margin-bottom: 0.5em; }
    .listingCheckbox { vertical-align: middle; text-align: center; }
 }

.template-usergroup-groupprefs,
.template-usergroup-userprefs {
    form { overflow: scroll; }
}

.template-actions-controlpanel #content-core {
    .addAction {
        height: 2em;
    }
    section.portlet {
        clear: both;
    }
    ol.configlets li {
        margin: 1.2em;
    }
}
