/* Change this part to change colors */

:root {
  --vinegar_main: #00847E;
  --vinegar_darker:#014441;
  //--vinear_lighter:;
}

#vinegar-bottle{
  
  z-index: 2;
}

#vinegar-bottle #vinegar-save_all button,
#vinegar-bottle #vinegar-short .vinegar-buttons .vinegar-button#vinegar-accept button,
#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box input:checked + .slider {
  background-color: var(--vinegar_main, #005498);
  color: var(--vinear_lighter, #f1f1f1);
}

#vinegar-bottle #vinegar-save_options button,
#vinegar-bottle #vinegar-short .vinegar-buttons .vinegar-button#vinegar-show_more button {
  border: 1px solid var(--vinegar_darker, #28568c);
}

#vinegar-bottle a, #vinegar-bottle .vinegar-title, #vinegar-bottle #vinegar-save_options button{
  color: var(--vinegar_darker, #28568c);
}

/* General stuff */

vinegar:not([type]){
  display: none;
}

vine-gar:not([type]){
  display: none;
}

vinegar[type="iframe"]{
  width: 90%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid black;
  margin: 0 5%;
}

/* This is when we can't fill the iframe via JS. I didn't want to put a noscript tag in every vinegar item */
vinegar[type="iframe"]:empty::after{
  content: "This feature requires JS. If you would like to use it please be sure to enable the latter.";
  padding: 0 20%;
}

vinegar iframe[src]+span{
  display: none;
}

vinegar iframe{
  width: 100%;
  height: 100%;
}

vinegar[type="map"]{
  position: relative;
  width: 100%;
  max-width: 400px;
  min-height: 290px;
  background-image: url(/vinegar/staticmap.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.vinegar_change, vinegar[type] {
  cursor: pointer;
}

vinegar[type] + iframe[src]{
  cursor:unset;
}

vinegar span{
  padding:10%;
}

#vinegar-bottle {
  position: fixed;
  color: #666;
  font-size: 12px;
  left: 0;
  background-color: white;
}

#vinegar-bottle.hidden{
    display:none;
}

#vinegar-bottle.short #vinegar-long,
#vinegar-bottle.long #vinegar-short {
  display: none;
}

#vinegar-bottle #vinegar-short {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

#vinegar-bottle #vinegar-short .vinegar-text {
  min-width: 60%;
  max-width: 80%;
  flex: 1 1 60%;
  order: 1;
}

#vinegar-bottle #vinegar-save_options button {
  background-color: #fff;
}

#vinegar-bottle #vinegar-short .vinegar-buttons .vinegar-button#vinegar-show_more button {
  background-color: #f1f1f1;
  color: #919191;
}

#vinegar-bottle .vinegar-title {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 20px;
}

#vinegar-bottle .vinegar-buttons button {
  min-width: 220px;
  padding: 16px 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

#vinegar-bottle .vinegar-buttons#vinegar-options_buttons button {
  padding: 12px;
}

#vinegar-bottle #vinegar-short .vinegar-buttons .vinegar-button button {
  margin-bottom: 8px;
}

#vinegar-bottle #vinegar-short .vinegar-buttons .vinegar-button#vinegar-show_more button {
  font-size: 90%;
}

#vinegar-bottle #vinegar-long .vinegar-options_title {
  border-bottom: 1px solid #a1a1a1;
  font-size: 24px;
  margin-top: 30px;
  padding-bottom: 20px;
}

#vinegar-bottle #vinegar-long #vinegar-back {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  width: 30px;
  align-items: center;
  padding: 4px;
  border: 0;
  margin: 10px 10px 0px 0px;
  background-color: white;
  cursor: pointer;
  font-size: 1em;
}

#vinegar-bottle #vinegar-long #vinegar-back svg {
  height: 20px;
}

#vinegar-bottle #vinegar-options_upper {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

#vinegar-bottle #vinegar-options_upper #vinegar-spacer {
  min-width: 40px;
  flex-grow: 1;
  flex-shrink: 1;
  order: 2;
}

#vinegar-bottle #vinegar-options_upper .vinegar-options_title {
  padding-bottom: 10px;
  font-size: 1.25em;
  font-weight: 500;
}

#vinegar-bottle #vinegar-options_upper #vinegar-options_buttons {
  order: 3;
}

#vinegar-bottle #vinegar-long .vinegar-option {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgb(161, 161, 161);
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin: 8px 0 8px 0;
}

#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box {
  margin-left: 10px;
}

#vinegar-bottle #vinegar-long .vinegar-option:first-child {
  margin-top: 0px;
}

#vinegar-bottle #vinegar-long .vinegar-option:last-child {
  margin-bottom: 0px;
}

#vinegar-bottle #vinegar-long #vinegar-options,
#vinegar-bottle #vinegar-long #vinegar-no_options {
  margin: 8px 0 8px 0;
}

#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_description_title {
  font-weight: 600;
}

#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 30px;
  flex: 0 0 52px;
  order: 2;
}

#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box input {
  width: 0;
  height: 0;
  opacity: 0;
}

#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box .slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ccc;
  border-radius: 30px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: auto;
}

#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_box
  input[type='checkbox']:disabled
  + .slider {
  opacity: 0.4;
}
#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_box
  .slider:before {
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 22px;
  height: 22px;
  background-color: white;
  border-radius: 50%;
  content: '';
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_box
  input:checked
  + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_description {
  flex: 1 1 60%;
  order: 1;
}

#vinegar-bottle .vinegar-buttons-wrapper {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
}

#vinegar-bottle #vinegar-save_options button {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  #vinegar-bottle #vinegar-short .vinegar-buttons {
    display: flex;
    flex-direction: column;
  }
  
  #vinegar-bottle #vinegar-short .vinegar-buttons:not(:first-child) {
    margin-left: 10px;
  }


  #vinegar-bottle #vinegar-short .vinegar-text {
    padding-right: 0;
  }

  #vinegar-bottle #vinegar-short .vinegar-text {
    max-width: inherit;
  }
  #vinegar-long .vinegar-buttons {
    flex-direction: column;
    float: none;
  }
  #vinegar-long .vinegar-button button{
    width: 100%;
  }
  #vinegar-long #vinegar-save_options{
    order: 2;
  }
  #vinegar-long #vinegar-save_all{
    order: 1;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  #vinegar-bottle {
    font-size: 16px;
  }

  #vinegar-bottle .vinegar-title {
    font-size: 24px;
    line-height: normal;
  }

  #vinegar-bottle .vinegar-buttons button {
    font-size: 16px;
  }
  #vinegar-bottle .vinegar-buttons-wrapper {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  #vinegar-bottle #vinegar-save_options button {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

#vinegar-bottle #vinegar-short .vinegar-buttons {
  order: 2;
}

#vinegar-long .vinegar-button div {
  padding-left: 10px;
}

#vinegar-long .vinegar-buttons {
  display: flex;
  justify-content: space-around;
}

/******************
 * Footer version *
 ******************/
#vinegar-bottle.footer{
  bottom: 0;
  max-height: 400px;
  padding: 20px 5% 20px 5%;
  margin-left: 0;
  box-shadow: 0px -1px 10px #888888;
  overflow-y: auto;
  max-width: 100%;
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  #vinegar-bottle.footer {
    max-height: 90%;
  }
}

#vinegar-bottle.footer #vinegar-short .vinegar-buttons {
  flex: 0 0 200px;
  margin: 20px 0px 20px 20px;
}

@media (min-width: 768px) {
  #vinegar-bottle.footer #vinegar-long .vinegar-buttons {
    float:right;
    padding-bottom: 15px;
  }
  #vinegar-bottle.footer #vinegar-save_all button {
    border-radius: 0 8px 8px 0;
  }
  
  #vinegar-bottle.footer #vinegar-save_options button {
    border-radius: 8px 0 0 8px;
  }
  #vinegar-bottle.footer #vinegar-long .vinegar-buttons {
    margin-bottom: 24px;
  }
}

#vinegar-bottle.footer .vinegar-buttons button {
  border-radius: 8px;
  padding: 9px 9px;
}

/*****************
 * Modal version *
 *****************/
#vinegar-bottle.modal{
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  top:0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
}

#vinegar-bottle.modal > div {
  max-width: 500px;
  width: 100%;
  background: white;
  box-shadow: 0px -1px 10px #888888;
  padding: 40px 2% 20px 2%;
  margin: 10px 10px 10px 10px;
  position: relative;
  border-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#vinegar-bottle.modal > div::-webkit-scrollbar {
  display: none;
}

#vinegar-bottle.modal #vinegar-long {
  max-height: 80%;
  overflow-y: scroll;
}

@media (max-width: 767px) {
  #vinegar-bottle.modal #vinegar-short .vinegar-buttons {
    align-items: center;
  }

  #vinegar-bottle.modal > div {
    padding: 40px 4% 15px 4%;
  }
}

#vinegar-bottle.modal #vinegar-short .vinegar-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 90%;
  padding-top: 10px;
}

#vinegar-long.modal .vinegar-buttons {
  margin-top: 15px;
  justify-content: space-around;
  margin-bottom: 15px;
}

