//* // VARIABLES //*//

//*// COLORS
//grays
@plone-gray-darker:							lighten(#000, 20%);   // #333
@plone-gray-dark:							lighten(#000, 30%);   // #4c4c4c
@plone-gray:								lighten(#000, 41%);   // #696969
@plone-gray-light:							lighten(#000, 46.5%); // #767676 //discreet
@plone-gray-lighter:						lighten(#000, 80%);   // #ccc //disableds like

//colors
@plone-link-color:							@textcolor; //plone blue made slightly darker for wcag 2.0
@plone-link-color-on-dark:					#16a1e3; //plone blue
											//if you need different contrast
@plone-link-color-on-grey:		  			#086ca3; //valid wcag 2.0
@plone-default-published-color:				@plone-link-color; //published and external same color
@plone-default-draft-color:					darken(@plone-toolbar-draft-color, 15%); // lime-yellow //draft is visible
@plone-default-pending-color:				darken(@plone-toolbar-pending-color, 15%); // orange
@plone-default-private-color:				@plone-toolbar-private-color; // red
@plone-default-internal-color:				darken(@plone-toolbar-internal-color, 15%); // is draft
@plone-default-internally-published-color:	darken(@plone-toolbar-internally-published-color, 5%); // is intranet

//** Disabled cursor for form controls and buttons.
@cursor-disabled:                           not-allowed;


//*// SCAFFOLDING
@plone-body-bg:            				     #fafafa; //almost-white
@plone-footer-bg:            				 #2e3133; //blued-darkgray
@plone-text-color:           				 @textcolor;
@plone-link-hover-color:     				 lighten(@plone-link-color, 25%);



//*// TYPOGRAPHY
@plone-font-family-sans-serif:  "Lexend", "Helvetica Neue", Helvetica, Arial, sans-serif; // Roboto seems Plone's DIN
@plone-font-family-condensed:   "Lexend", "Roboto Condensed", "Arial Narrow", sans-serif; //just on toolbar
@plone-font-family-serif:       Georgia, "Times New Roman", Times, serif;
@plone-font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@plone-font-family-base:        @plone-font-family-sans-serif;

@plone-font-size-base:          20px;
@plone-font-size-large:         ceil((@plone-font-size-base * 1.25)); // ~18px
@plone-font-size-small:         ceil((@plone-font-size-base * 0.85)); // ~12px

@plone-font-size-h1:            floor((@plone-font-size-base * 1.35)); // ~36px
@plone-font-size-h2:            floor((@plone-font-size-base * 1.35)); // ~30px
@plone-font-size-h3:            ceil((@plone-font-size-base * 1.2)); // ~24px
@plone-font-size-h4:            ceil((@plone-font-size-base * 1.2)); // ~18px
@plone-font-size-h5:            @plone-font-size-base;
@plone-font-size-h6:            ceil((@plone-font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@plone-line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@plone-line-height-computed:    floor((@plone-font-size-base * @plone-line-height-base)); // ~20px

@plone-headings-font-family:    inherit;
	@plone-headings-font-weight:    @plone-font-weight-bold;
	@plone-headings-line-height:    1.1;
	@plone-headings-color:          inherit;

@plone-font-weight-light:		300;
@plone-font-weight-regular:		400;
@plone-font-weight-medium:		500;
@plone-font-weight-bold:		700;



//*// COMPONENTS
@plone-padding-base-vertical:     6px;
	@plone-padding-base-horizontal:   12px;
	@plone-padding-large-vertical:    10px;
	@plone-padding-large-horizontal:  16px;
	@plone-padding-small-vertical:    5px;
	@plone-padding-small-horizontal:  10px;
	@plone-padding-xs-vertical:       1px;
	@plone-padding-xs-horizontal:     5px;
	@plone-line-height-large:         1.33;
	@plone-line-height-small:         1.5;
@plone-border-radius-base:        4px;
	@plone-border-radius-large:       6px;
	@plone-border-radius-small:       2px;
//** Global color for active items (e.g., navs or dropdowns).
@plone-component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
@plone-component-active-bg:       @plone-link-color;
//** Width of the `border` for generating carets that indicate dropdowns.
@plone-caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
@plone-caret-width-large:         5px;



//*// TABLES
@plone-table-cell-padding:            8px;
@plone-table-condensed-cell-padding:  5px;
@plone-table-bg:                      #fff;
@plone-table-bg-accent:               #f6f6f6;
@plone-table-bg-hover:                #f0f0f0;
@plone-table-bg-active:               @plone-table-bg-hover;
@plone-table-border-color:            #e5e5e5;



//*// STATES
@plone-state-info-text:    		            rgba(0,0,0,.5);
	@plone-state-info-bg:     		            #aad6ea;
	@plone-state-info-border: 		            1px solid darken(@plone-state-info-bg,4%);
	@plone-state-info-bullet:					#3e7b91;
@plone-state-warning-text:          	    rgba(0,0,0,.5);
	@plone-state-warning-bg:          	 	    #ebd380;
	@plone-state-warning-border:        	    1px solid darken(@plone-state-warning-bg,6%);
	@plone-state-warning-bullet:				#bc8d0d;
@plone-state-error-text:              		rgba(0,0,0,.5);
	@plone-state-error-bg:                		#EC9C8D;
	@plone-state-error-border:            		1px solid darken(@plone-state-error-bg,2%);
	@plone-state-error-bullet:					#ac493f;


//*// TOOLBAR

@plone-left-toolbar-expanded: 120px;
@plone-left-toolbar: 60px;

//*// DEVICES
@plone-screen-xs-min:              480px;
@plone-screen-sm-min:              768px;
@plone-screen-md-min:              992px;
@plone-screen-lg-min:              1200px;
@plone-screen-xl-min:              1500px;

@plone-screen-xs-max:              (@plone-screen-sm-min - 1);
@plone-screen-sm-max:              (@plone-screen-md-min - 1);
@plone-screen-md-max:              (@plone-screen-lg-min - 1);
@plone-screen-lg-max:              (@plone-screen-xl-min - 1);

@plone-grid-columns:        12;
@plone-grid-gutter-width:         30px;
@plone-grid-float-breakpoint:     @plone-screen-sm-min;
//** Point at which the navbar begins collapsing.
@plone-grid-float-breakpoint-max: (@plone-grid-float-breakpoint - 1);

//*// CONTAINERS
@plone-container-xs:                 (540px + @plone-grid-gutter-width);
@plone-container-sm:                 (720px + @plone-grid-gutter-width);
@plone-container-md:                 (960px + @plone-grid-gutter-width);
@plone-container-lg:                 1200px;
@plone-container-xl:                 1440px;

//*// CONTAINER SIZE ALIASES
@plone-container-mobile:              @plone-container-xs;
@plone-container-tablet:              @plone-container-sm;
@plone-container-desktop:             @plone-container-md;
@plone-container-large-desktop:       @plone-container-lg;
@plone-container-extra-large:         @plone-container-xl;


//*// Buttons
@plone-btn-standalone-color:          		@plone-gray-dark;
	@plone-btn-standalone-bg:             	   	#e5e5e5;
	@plone-btn-standalone-border:          	   	darken(@plone-btn-standalone-bg, 20%);
@plone-btn-context-color:              		#fff;
	@plone-btn-context-bg:                 		#007bb1;
	@plone-btn-context-border:             		darken(@plone-btn-context-bg, 10%);
@plone-btn-destructive-color:              #fff;
	@plone-btn-destructive-bg:                 #bb4f45;
	@plone-btn-destructive-border:             darken(@plone-btn-destructive-bg, 10%);

//*// FORMS
//** `<input>` background color
@plone-input-bg:                       #fff;
//** `<input disabled>` background color
@plone-input-bg-disabled:              @plone-table-border-color;
//** Text color for `<input>`s
@plone-input-color:                    @plone-gray;
//** `<input>` border color
@plone-input-border:                   #ccc;
//** `<input>` border radius
@plone-input-border-radius:            @plone-border-radius-base;
//** Border color for inputs on focus
@plone-input-border-focus:             @plone-portlet-list-bullet;
//** Placeholder text color
@plone-input-color-placeholder:        @plone-gray-light;
//** Default `.form-control` height
@plone-input-height-base:              (@plone-line-height-computed + (@plone-padding-base-vertical * 2) + 2);
//** Large `.form-control` height
@plone-input-height-large:             (ceil(@plone-font-size-large * @plone-line-height-large) + (@plone-padding-large-vertical * 2) + 2);
//** Small `.form-control` height
@plone-input-height-small:             (floor(@plone-font-size-small * @plone-line-height-small) + (@plone-padding-small-vertical * 2) + 2);
@plone-legend-color:                   @plone-gray-dark;
@plone-legend-border-color:            #e5e5e5;

//** Background color for textual input addons
@plone-input-group-addon-bg:           @plone-gray-lighter;
//** Border color for textual input addons
@plone-input-group-addon-border-color: @plone-input-border;



//*// ALERTS
@plone-alert-icon-size:							20px;
@plone-alert-padding:							15px;
	@plone-alert-border-radius:					4px;
	@plone-alert-link-font-weight:				@plone-font-weight-regular; //it depends on your font-family
@plone-alert-info-bg:						@plone-state-info-bg;
	@plone-alert-info-text:						@plone-state-info-text; //the text on alert is black with opacity to use the same color and match the bg.
	@plone-alert-info-border:					@plone-state-info-border;
	@plone-alert-info-bullet:					@plone-state-info-bullet;
@plone-alert-warning-bg:					@plone-state-warning-bg;
	@plone-alert-warning-text:					@plone-state-warning-text;
	@plone-alert-warning-border:				@plone-state-warning-border;
	@plone-alert-warning-bullet:				@plone-state-warning-bullet;
@plone-alert-error-bg:						@plone-state-error-bg;
	@plone-alert-error-text:					@plone-state-error-text;
	@plone-alert-error-border:					@plone-state-error-border;
	@plone-alert-error-bullet:					@plone-state-error-bullet;



//*// PORTLETS
@plone-border-base:							1px solid;
@plone-portlet-header-bg:					#f2f1f1;
@plone-portlet-header-color:				@plone-gray;
@plone-portlet-content-bg:					#fff;
@plone-portlet-footer-bg:					#fcfcfd;
@plone-portlet-border:						@plone-border-base #e5e5e5;
@plone-portlet-contrast-bg:					#e5e5e5;
@plone-portlet-event-bullet:				#dbecfe;
@plone-portlet-list-bullet:					#64bee8;
@plone-portlet-list-hover-bg:				#fcfcfd;
@plone-portlet-list-small-text:				@plone-gray;
@plone-portlet-navtree-maxlevel:			5;


//*// THUMBS
//** Padding around the thumbnail image
@plone-thumbnail-padding:           4px;
//** Thumbnail background color
@plone-thumbnail-bg:                @plone-body-bg;
//** Thumbnail border color
@plone-thumbnail-border:            #ddd;
//** Thumbnail border radius
@plone-thumbnail-border-radius:     @plone-border-radius-base;
//** Custom text color for thumbnail captions
@plone-thumbnail-caption-color:     @plone-text-color;
//** Padding around the thumbnail caption
@plone-thumbnail-caption-padding:   9px;



//*// BREADCRUMBS
@plone-breadcrumb-padding-vertical:   8px;
@plone-breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
@plone-breadcrumb-bg:                 darken(@plone-portlet-header-bg,5%);
//** Breadcrumb text color
@plone-breadcrumb-color:              @plone-gray-lighter;
//** Text color of current page in the breadcrumb
@plone-breadcrumb-active-color:       @plone-gray-light;


//*// SITE NAV
@plone-sitenav-height:                        50px;
	@plone-sitenav-margin-bottom:             @plone-line-height-computed;
	@plone-sitenav-border-radius:             @plone-border-radius-base;
	@plone-sitenav-padding-horizontal:        floor((@plone-grid-gutter-width / 2));
	@plone-sitenav-padding-vertical:          ((@plone-sitenav-height - @plone-line-height-computed) / 2);
	@plone-sitenav-collapse-max-height:       340px;
	@plone-sitenav-link-padding:              10px 15px;
	@plone-sitenav-link-hover-bg:             #7099bf;
	@plone-sitenav-bg:      			      @plone-link-color;
	@plone-sitenav-hover-bg:      			  @plone-link-color;

//*// PAGINATION
@plone-pagination-color:                     @plone-link-color;
	@plone-pagination-bg:                        @plone-portlet-content-bg;
	@plone-pagination-border:                    @plone-portlet-border;
@plone-pagination-hover-color:               @plone-link-hover-color;
	@plone-pagination-hover-bg:                  @plone-portlet-header-bg;
	@plone-pagination-hover-border:              @plone-portlet-border;
@plone-pagination-active-color:              @plone-portlet-content-bg;
	@plone-pagination-active-bg:                 @plone-link-color;
	@plone-pagination-active-border:             @plone-border-base @plone-link-color;
@plone-pagination-disabled-color:            @plone-gray-lighter;
	@plone-pagination-disabled-bg:               @plone-portlet-footer-bg;
	@plone-pagination-disabled-border:           @plone-portlet-border;



//*// CODE
@plone-code-color:                  #c7254e;
	@plone-code-bg:                     #f9f2f4;
@plone-kbd-color:                   #fff;
	@plone-kbd-bg:                      #333;
@plone-pre-bg:                      #f5f5f5;
	@plone-pre-color:                   @plone-gray-dark;
	@plone-pre-border-color:            #ccc;
	@plone-pre-scrollable-max-height:   340px;



//*// TYPE
//** Text muted color
@plone-text-discreet:               @plone-gray;
//** Abbreviations and acronyms border color
@plone-abbr-border-color:           @plone-gray-light;
//** Headings small color
@plone-headings-small-color:        @plone-gray-light;
//** Blockquote small color
@plone-blockquote-small-color:      @plone-gray-light;
//** Blockquote font size
@plone-blockquote-font-size:        (@plone-font-size-base * 1.25);
//** Blockquote border color
@plone-blockquote-border-color:     @plone-gray-lighter;
//** Page header border color
@plone-page-header-border-color:    @plone-gray-lighter;



//*// MISC
//** Horizontal line color.
@plone-hr-border:                   @plone-gray-lighter;
//** Horizontal offset for forms and lists.
@plone-component-offset-horizontal: 180px;


//*// END
